import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo"
import NewsDetail from "pages/news/newsDetail"

export const Head = ({ data }) => (
  <SEO
    title={data.contentfulPost.title}
    pathname={`/${data.contentfulPost.slug}`}
  />
)

export const query = graphql`
  query($id: String!) {
    contentfulPost(id: { eq: $id }) {
      id
      slug
      date
      title
      body {
        raw
      }
      createdAt
      covers {
        id
        title
        gatsbyImageData(layout: CONSTRAINED, width: 2000)
      }
    }
  }
`

export default ({ data }) => (
  <Layout>
    <NewsDetail data={data.contentfulPost} />
  </Layout>
)
