import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React from "react"
import { format } from "date-fns"
import "./styles.scss"

export default ({ data }) => (
  <>
    <Link to="/news">
      <span className="go-news-list">&lt;&nbsp;&nbsp;回最新消息列表</span>
    </Link>
    <div className="news-detail-container">
      <div className="img-body">
        {
          data.covers.map(cover => (
            <GatsbyImage
              image={cover.gatsbyImageData}
              key={cover.id}
              alt={cover.title}
            />
          ))
        }
      </div>
      <div className="content-body">
        <div className="items" key={data.id}>
          <div className="title">{data.title}</div>
          <div className="date">{format(new Date(data.date || data.createdAt), 'yyyy/MM/dd')}</div>
          <div className="desc">
            {renderRichText(data.body)}
          </div>
        </div>
      </div>
    </div>
  </>
)
